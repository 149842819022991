/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * Sends analytics data for the confirmation step of the sample flow.
 */
export const addsSampleConfirmation = () => {
  if ($('.sample-confirm-container').length > 0) {
    const currentID = localStorage.getItem('currentId');
    const currentProductName = localStorage.getItem('currentProductName');

    pushToDataLayer({
      event: 'ga4_sample_form',
      ga4_data: {
        action_type: 'Confirmation',
        step_name: 'Order Confirmation',
        step_number: 3,
        single_item_id: currentID,
        single_item_name: currentProductName
      }
    });
  }
};

/**
 * Toggled from accordion.js
 * @param {String} title
 */
export const analyticsAccordionItemOpen = title => {
  pushToDataLayer({
    event: 'engagement',
    action: 'accordion-open',
    label: title
  });
};

/**
 * Handles the analytics tracking for sample requests. This function is responsible for sending data
 * to the dataLayer at different steps of the sample request process. It tracks user interactions
 * such as initiating the request form, selecting a product, filling out the form, and confirming the order
 *
 * Toggled from productSample.js
 * @param {String} step - Step number / Confirmation for last step
 * @param {String} selectedItemName
 */
export const analyticsSampleRequest = (step, selectedItemName) => {
  const $formSample = $('.js-form-sample');
  const stepNumber = parseInt(step.split(' ')[1], 10);
  const productId = parseInt(localStorage.getItem('currentId'));
  let isAnalyticsPushed = false;

  // Initial push to dataLayer
  pushToDataLayer({
    event: 'gaEvent',
    eventTrigger: 'sampleRequest',
    eventCategory: 'Sample request',
    eventAction: step,
    eventLabel: selectedItemName
  });

  // Step 1 - Push selected product info to dataLayer.
  if (stepNumber === 1) {
    pushToDataLayer({
      event: 'ga4_sample_form',
      ga4_data: {
        action_type: 'Initiate request form',
        step_name: 'Choose your product',
        step_number: stepNumber,
        single_item_id: '',
        single_item_name: selectedItemName
      }
    });
  }

  if ($formSample.length) {
    const formId = $('form').attr('id');
    sessionStorage.setItem('formId', formId);

    pushToDataLayer({
      event: 'ga4_form_visible',
      ga4_data: {
        form_name: 'Sample Order',
        form_id: formId
      }
    });

    /*
      Step 2/3 - On some publications, the form is on the second and somewhere on the third step,
      such as the example with the Polish site. This means that on those sites there is a step 2 which
      is an extra step that should not be followed with push to dataLayer. (Jira: HMS-6045)
    */
    $formSample.find('.form-field').on('change', () => {
      const isFormValid = $formSample.find('.valid').length > 0 || $('.field-checkbox').is(':checked');

      if (isFormValid && !isAnalyticsPushed) {
        pushToDataLayer({
          event: 'ga4_sample_form',
          ga4_data: {
            action_type: 'Filling out the form',
            step_name: 'Delivery information',
            step_number: 2,
            single_item_id: productId,
            single_item_name: selectedItemName
          }
        });

        pushToDataLayer({
          event: 'ga4_form_start',
          ga4_data: {
            form_name: 'Sample Order',
            form_id: formId
          }
        });
        isAnalyticsPushed = true;
      }
    });
  }
};

/**
 * Tracks a product comparison event in Google Analytics.
 *
 * @param {string} productId - The ID of the selected product.
 * @param {string} selectedProductName - The name of the selected product.
 */
export const analyticsCompareProductListener = (productId, selectedProductName) => {
  pushToDataLayer({
    event: 'ga4_product_comparison',
    ga4_data: {
      action_type: 'Add product',
      single_item_id: productId,
      single_item_name: selectedProductName,
      link_text: selectedProductName
    }
  });
};

/**
 * Events after the form has been sent
 * @param {Node} $form
 */
export const analyticsFormSend = $form => {
  function getProductName() {
    if (window.HMS.ProductSample && window.HMS.ProductSample.productSelectedName
      && window.HMS.ProductSample.productSelectedName.length) {
        return window.HMS.ProductSample.productSelectedName;
    }

    if ($('#AvailableSampleProducts').length) {
      return $('#AvailableSampleProducts').select2('data')[0].text;
    }

    return '';
  }

  switch ($form.data('formtype').toLowerCase()) {
    case 'sampleorder':
      if ($('.js-sample-form-status').length) {
        let status = $('.js-sample-form-status').data('form-status');
        if (status === '') {
          status = 'Blacklisted';
        }
        analyticsSampleRequest(status, getProductName());
      }

      pushToDataLayer({
        event: 'ga4_form_submit',
        ga4_data: {
          form_name: 'Sample Order',
          form_id: sessionStorage.getItem('formId')
        }
      });
      break;
    case 'contactus':
      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'formSubmit',
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: 'Contact us'
      });

      pushToDataLayer({
        event: 'ga4_form_submit',
        ga4_data: {
          form_name: 'Contact us',
          form_id: $('form').attr('id')
        }
      });
      break;
    case 'askthedoctor':
      pushToDataLayer({
        event: 'engagement',
        action: 'askTheDoctor'
      });

      pushToDataLayer({
        event: 'ga4_form_submit',
        ga4_data: {
          form_name: 'Ask the doctor',
          form_id: sessionStorage.getItem('trackedFormId')
        }
      });
      break;
    case 'newsletter':
      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'formSubmit',
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: 'Newsletter',
        eventValue: undefined
      });

      pushToDataLayer({
        event: 'ga4_form_submit',
        ga4_data: {
          form_name: 'Newsletter form',
          form_id: sessionStorage.getItem('trackedFormId')
        }
      });
      break;
    default:
      break;
  }

  if ($form.find('.ga-email-optin').length > 0) {
    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'emailOptIn',
      eventCategory: 'Email Opt-In',
      eventAction: 'Subscribe',
      eventLabel: window.location
    });
  }
};
